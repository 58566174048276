/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
import React from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Link } from '@abyss/web/ui/Link';
import { config } from '@abyss/api/tools/config';

export const InvalidTokenErrorPage = () => {
  const openAndClose = () => {
    console.log(`Redirecting to ${config('LOG_BACK_IN_URL')}`);
    // Open a new tab
    const newWindow = window.open(`${config('LOG_BACK_IN_URL')}`, '_blank');

    // Close the current tab
    window.close();

    // Ensure the new tab is focused
    if (newWindow) {
      newWindow.focus();
    }
  };
  return (
    <React.Fragment>
      <PageBody>
        <br />
        <Heading>Security Token Expired</Heading>
        <br />
        <br />
        <p>
          <br />
          <Heading offset={2}>
            The security token used in your previous session has expired.
          </Heading>
          <br />
          <br />
          Please try closing this tab and any other{' '}
          <strong>dFEDS's tabs</strong> that may still be open, then open a new
          tab.
          <br />
          <br />
          <br />
          We recommend opening a private window while using the app - Press{' '}
          <strong>Ctrl + Shift + N</strong> to open a private window.
          <br />
          <br />
          <br />
          <Link onClick={openAndClose} openNewWindow>
            Please click here to sign back in. You can then close this tab.
          </Link>
        </p>
      </PageBody>
    </React.Fragment>
  );
};
