import { useQuery } from '@abyss/web/hooks/useQuery';
// @ts-ignore
import Search from './Search.gql';

export const useSearch = (options) => {
  return useQuery(Search, {
    ...options,
    url: '/api/graphql',
    requestPolicy: 'no-cache',
  });
};
