import { constants } from './constants';

export const constraints = {
  procedureCodes: {
    maxLen: 255,
    minLen: 1,
    valueType: 'chars',
    exclude: [], // add any strings we do not want to allow, for example '---' etc.
    valid: (pc: string) => {
      if ([constants.ENTER_PROC_CODE_MSG, '', null, undefined].includes(pc))
        return {
          valid: false,
          message: 'Missing Procedure Codes',
        };
      if (pc.length > constraints.procedureCodes.maxLen)
        return {
          valid: false,
          message: `Procedure Codes can not exceed ${constraints.procedureCodes.maxLen} chars`,
        };
      if (
        constraints.procedureCodes.exclude.some((value) => {
          return pc?.includes(value);
        })
      )
        return {
          valid: false,
          message: `Procedure Codes can not contain ${JSON.stringify(
            constraints.procedureCodes.exclude
          )}`,
        };
      return { valid: true, message: 'Validated!' };
    },
  },
  onFilter: {
    tin: {
      maxLen: 20,
      minLen: 1,
      valueType: 'chars',
      exclude: [], // add any strings we do not want to allow, for example '---' etc.
      isValid: (tin: string) => {
        if (tin?.match(/^[\w\W]{3,20}$/g) == null)
          return {
            valid: false,
            message: `TIN value must be between ${constraints.onFilter.tin.minLen} and ${constraints.onFilter.tin.maxLen} chars.`,
          };
        return { valid: true, message: 'Validated!' };
      },
      isValidPartial: (tin: string) => {
        return tin?.match(/^[\w\W]{1,21}$/g) != null;
      },
    },
    npi: {
      maxLen: 10,
      minLen: 10,
      valueType: 'digits',
      exclude: [], // add any strings we do not want to allow, for example '---' etc.
      isValid: (npi: string) => {
        return constraints.npi.isValid(npi);
      },
      isValidPartial: (npi: string) => {
        return npi?.match(/^[0-9]{10}$/g) != null;
      },
    },
    mpin: {
      maxLen: 8,
      minLen: 5,
      valueType: 'digits',
      exclude: [], // add any strings we do not want to allow, for example '---' etc.
      isValid: (mpin: string) => {
        return constraints.mpin.isValid(mpin);
      },
      isValidPartial: (npi: string) => {
        return npi?.match(/^[0-9]{5,8}$/g) != null;
      },
    },
    ein: {
      maxLen: 9,
      minLen: 1,
      valueType: 'digits',
      exclude: [], // add any strings we do not want to allow, for example '---' etc.
      isValid: (ein: string) => {
        return constraints.mpin.isValid(ein);
      },
      isValidPartial: (ein: string) => {
        return ein?.match(/^[0-9]{8,9}$/g) != null;
      },
    },
    name: {
      maxLen: 50,
      minLen: 0,
      valueType: 'digits',
      exclude: [], // add any strings we do not want to allow, for example '---' etc.
    },
    city: {
      maxLen: 50,
      minLen: 0,
      valueType: 'digits',
      exclude: [], // add any strings we do not want to allow, for example '---' etc.
    },
    orgName: {
      maxLen: 150,
      minLen: 0,
      valueType: 'digits',
      exclude: [], // add any strings we do not want to allow, for example '---' etc.
    },
    address: {
      maxLen: 150,
      minLen: 0,
      valueType: 'digits',
      exclude: [], // add any strings we do not want to allow, for example '---' etc.
    },
    postalCode: {
      maxLen: 5,
      minLen: 5,
      valueType: 'digits',
      exclude: [], // add any strings we do not want to allow, for example '---' etc.
    },
  },
  tin: {
    isValid: (tin: string) => {
      if (tin?.match(/^[0-9]{9}$/g) == null)
        return { valid: false, message: `TIN value must be 9 digits.` };
      return { valid: true, message: 'Validated!' };
    },
  },
  npi: {
    isValid: (npi: string) => {
      if (npi?.match(/^[0-9]{10}$/g) == null)
        return { valid: false, message: `NPI value must be 10 digits.` };
      return { valid: true, message: 'Validated!' };
    },
  },
  mpin: {
    isValid: (npi: string) => {
      if (npi?.match(/^[0-9]{5,8}$/g) == null)
        return {
          valid: false,
          message: `MPIN value must be between 5-8 digits.`,
        };
      return { valid: true, message: 'Validated!' };
    },
  },
  ein: {
    isValid: (ein: string) => {
      if (ein?.match(/^[0-9]{8,9}$/g) == null)
        return {
          valid: false,
          message: `EIN value must be between 8-9 digits.`,
        };
      return { valid: true, message: 'Validated!' };
    },
  },
};
