/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { RouteUrl } from '@src/common/util/routeUrl';
import { NavLinkProps, Permissions } from './types';

interface AllowableNavLink extends NavLinkProps {
  allowed: boolean;
}

export function getAuthorizedNavLinks(
  permissions: Permissions
): NavLinkProps[] {
  return getAllNavLinks(permissions).filter(({ allowed }) => allowed);
}

function getAllNavLinks({
  admin,
  canReadFfc,
  canWriteFfc,
  canSearchFfc,
  canSearchEpim,
  canSearchOptumRx,
}: Permissions): AllowableNavLink[] {
  const size = 40;
  return [
    {
      id: 'admin',
      title: 'Administration',
      href: RouteUrl.admin,
      icon: { icon: 'code', size },
      allowed: admin,
    },
    {
      id: 'event-search',
      title: 'Event Search',
      href: RouteUrl.eventsSearch,
      icon: { icon: 'data_exploration', size, variant: 'outlined' },
      allowed: false,
    },
    {
      id: 'global-provider-search',
      title: 'Search Providers',
      href: RouteUrl.providerSearch,
      icon: { icon: 'search', size },
      allowed: canSearchFfc || canSearchEpim || canSearchOptumRx,
    },
    {
      id: 'manage-ffc-cases',
      title: 'Manage FFC Cases',
      href: RouteUrl.providerManage,
      icon: { icon: 'manage_search', size },
      allowed: canReadFfc,
    },
    {
      id: 'create-provider',
      title: 'Create FFC Provider',
      href: RouteUrl.providerCreate,
      icon: { icon: 'create', size },
      allowed: canWriteFfc,
    },
  ];
}
