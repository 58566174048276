/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Button } from '@abyss/web/ui/Button';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Breadcrumbs } from '@src/abyss/web/ui/Breadcrumbs';
import { VisuallyHidden } from '@abyss/web/ui/VisuallyHidden';
import { isEmpty } from 'lodash';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Layout } from '@abyss/web/ui/Layout';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Label } from '@abyss/web/ui/Label';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { constraints } from '@src/routes/provider/common/constraints';
import { useHasRole } from '@src/hooks/useHasRole';
import { Alert } from '@abyss/web/ui/Alert';
import { constants } from '../../common/constants';
import { publishNessEvent } from '../../../../common/logging/publishNessEvent';

/* ToDo use ws
// Create WebSocket connection.
const socket = new WebSocket("ws://localhost:4000");

// Connection opened
socket.addEventListener("open", (event) => {
	socket.send("Hello Server!");
});

*/

export const Filter = ({ previousFormData, triggerFormDataUpdate, props }) => {
  const myDiv = useRef();

  const [, setFfcCheckBoxDisable] = useState(false);

  const [, setCheckBoxGrpSources] = useState([]);
  const [canSearchFfc, setCanSearchFfc] = useState(false);
  const [canSearchOther, setCanSearchOther] = useState(false);
  const [, getSearchRoles] = useHasRole({
    onCompleted: (response) => {
      const roles = Object.freeze(response?.data?.hasRole);

      setCanSearchFfc(roles[constants.ROLE_SEARCH_FFC]);
      // we could restrict this even further - only allow source checkbox based not on the umbrella field canSearchOther
      // but on individual fields for say epim, ffc etc.
      setCanSearchOther(
        roles[constants.ROLE_SEARCH_OPTUMRX] &&
          roles[constants.ROLE_SEARCH_EPIM] &&
          roles[constants.ROLE_SEARCH_CMS]
      );

      const src = [];
      if (roles[constants.ROLE_SEARCH_FFC]) src.push(constants.SOURCE_FFC);
      if (roles[constants.ROLE_SEARCH_OPTUMRX])
        src.push(constants.SOURCE_OPTUMRX);
      // we have a requirement not to initial check the epim source
      // if (roles[constants.ROLE_SEARCH_EPIM]) src.push(constants.SOURCE_EPIM);
      if (roles[constants.ROLE_SEARCH_CMS]) src.push(constants.SOURCE_CMS);

      setCheckBoxGrpSources(src);

      const resetWith = {
        sources: src,
        ...previousFormData,
      };
      form.reset(resetWith, {
        keepErrors: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      });
    },
    onError: () => {
      loadUserRoles({});
    },
  });
  const [gotUserRoles, loadUserRoles] = useState(() => {
    // make call to middleware to see if user has these roles
    return getSearchRoles({
      variables: {
        roles: Object.freeze([
          constants.ROLE_SEARCH_CMS,
          constants.ROLE_SEARCH_EPIM,
          constants.ROLE_SEARCH_FFC,
          constants.ROLE_SEARCH_OPTUMRX,
        ]),
      },
    });
  });

  const { navigate } = useRouter();
  const [, setIdTypeValue] = useState('');
  const [pageUpdated, setPageUpdated] = useState();

  /**
   * If the user selects an Id Type then must set an Id Value and vice versa
   * Will report an validation error if the user has begun to modify the Id Value field but not yet selected an Id Type
   *
   * @param idv the value in the TextInput component
   */
  const onIdValueChange = (idv) => {
    setPageUpdated(idv);
    switch (form.getValues('identifierTypeCode')) {
      case constants.NPI_ID_TYPE:
      case constants.MPIN_ID_TYPE:
      case constants.TIN_ID_TYPE:
      case constants.EIN_ID_TYPE:
        // ok, they've selected an Id Type - validation will occur elsewhere
        break;
      default:
        //  No ID Type Selected yet - let user know they must select one
        form.setError('identifierTypeCode', {
          type: 'manual',
          message: `You must select an ID Type`,
        });
    }
  };

  /** called when the ID Type field is cleared */
  const onIdValueClear = (idt: any) => {
    form.clearErrors('identifierTypeCode');
    setPageUpdated(idt);
    setIdTypeValue('');
  };

  /**
   * Called when the ID Type has changed.
   * It would be tricky to try and reuse any ID value in TextInput, so it's just easier to clear it out whenever a new ID type is selected
   */
  const onIdTypeChange = (idt) => {
    form.clearErrors(['identifierTypeCode', 'identifierTypeValue']);
    if (!idt) {
      form.setValue('identifierTypeValue', '');
      setPageUpdated(idt);
      return;
    }

    let errMsg;
    switch (idt) {
      case constants.NPI_ID_TYPE:
        errMsg = 'NPI value must be 10 digits';
        break;
      case constants.MPIN_ID_TYPE:
        errMsg = 'MPIN value must be between 5-8 digits.';
        break;
      case constants.TIN_ID_TYPE:
        errMsg = 'TIN value must be 1-20 chars.';
        break;
      case constants.EIN_ID_TYPE:
        errMsg = 'EIN value must between 8-9 digits.';
        setFfcCheckBoxDisable(true);
        break;
      default:
        form.clearErrors('identifierTypeValue');
    }
    form.setError('identifierTypeValue', {
      type: 'manual',
      message: `${errMsg}`,
    });
    // don't forget to tell form there's been a change
    setPageUpdated(idt);
  };

  /**
   * When user clears the selection we need to make sure we also clear any field validation message
   */
  const onClearSelection = (model: string) => {
    return () => {
      form.clearErrors(model);
      setPageUpdated(form.getValues(model));
    };
  };

  const [filterPropsValid, setFilterPropsValid] = useState(true);

  const form = useForm({
    defaultValues: {
      sources: ['ffc', 'optum-rx'],
      ...previousFormData,
    },
  });

  /** Validates the constraints for the Id Value field */
  const idTypeAndValueProvided = () => {
    form.clearErrors(['identifierTypeValue']);
    const type = form.getValues('identifierTypeCode');
    if (!type) {
      setPageUpdated(false);
      return;
    }

    const identifierTypeValue = form.getValues('identifierTypeValue');

    let errMsg = '';
    // ok, so does the partial value entered so far match the correct pattern for the Id Type
    switch (type) {
      case constants.NPI_ID_TYPE:
        errMsg = constraints.onFilter.npi.isValidPartial(identifierTypeValue)
          ? ''
          : '10 digits required';
        break;
      case constants.MPIN_ID_TYPE:
        errMsg = constraints.onFilter.mpin.isValidPartial(identifierTypeValue)
          ? ''
          : '5-8 digits required';
        break;
      case constants.TIN_ID_TYPE:
        errMsg = constraints.onFilter.tin.isValidPartial(identifierTypeValue)
          ? ''
          : '1-20 chars required';
        break;
      case constants.EIN_ID_TYPE:
        errMsg = constraints.onFilter.ein.isValidPartial(identifierTypeValue)
          ? ''
          : '8-9 digits required';
        break;
      default: //  No ID Type Selected yet
    }

    if (errMsg) {
      form.setError('identifierTypeValue', {
        type: 'manual',
        message: `${errMsg}`,
      });
      return;
    }

    form.clearErrors('identifierTypeCode');
  };

  /**
   * Validates the constraints for a form field called 'prop'
   * @param prop the name of the form field
   * @param min the min len for that field
   * @param max the max len for that field
   * @param valueType is it a 'digit' or 'char' type
   */
  const propValueWithinRange = (
    prop,
    min,
    max,
    valueType = 'chars'
  ): boolean => {
    const value = form.getValues(prop) || '';
    form.clearErrors(prop);
    if (value.length < min) {
      form.setError(prop, {
        type: 'manual',
        message: `Min ${min} ${valueType} required`,
      });
      return false;
    }
    if (value.length > max) {
      form.setError(prop, {
        type: 'manual',
        message: `Max ${max} ${valueType} allowed`,
      });
      return false;
    }
    form.clearErrors(prop);
    return true;
  };

  /*
	 Blind search is NOW allowed but validation on ID values etc. will still be performed
	 */
  const validateFilterProps = () => {
    // we must have at least one source selected to perform the search
    form.clearErrors('sources');
    if (form.getValues('sources')?.length === 0) {
      setFilterPropsValid(false);
      return;
    }
    setFilterPropsValid(true);

    // make sure the Id Type/Value combos are good
    idTypeAndValueProvided();

    // the state will either be there or not and either way it's a valid search param so let's ignore this one

    // make sure the postal code is good
    form.clearErrors('postalCode');
    if (form.getValues('postalCode')?.length > 0) {
      propValueWithinRange(
        'postalCode',
        constraints.onFilter.postalCode.minLen,
        constraints.onFilter.postalCode.maxLen,
        constraints.onFilter.postalCode.valueType
      );
    }

    propValueWithinRange(
      'streetAddress',
      constraints.onFilter.address.minLen,
      constraints.onFilter.address.maxLen
    );

    propValueWithinRange(
      'firstName',
      constraints.onFilter.name.minLen,
      constraints.onFilter.name.maxLen
    );

    propValueWithinRange(
      'lastName',
      constraints.onFilter.name.minLen,
      constraints.onFilter.name.maxLen
    );

    propValueWithinRange(
      'city',
      constraints.onFilter.city.minLen,
      constraints.onFilter.city.maxLen
    );

    propValueWithinRange(
      'orgName',
      constraints.onFilter.orgName.minLen,
      constraints.onFilter.orgName.maxLen
    );

    // if we have any validation errors then do not allow search to happen
    setFilterPropsValid(isEmpty(form.formState.errors));
  };

  useEffect(() => {
    validateFilterProps();
  }, [pageUpdated, props, gotUserRoles]);

  /* ToDO use ws
	useEffect(() => {
		// Listen for messages
		socket.addEventListener("message", (event) => {
			console.log("Message from server ", event.data);
		});


	}, [socket]);
*/
  const onError = (errors) => {
    console.log('Error fired: %j', errors);
  };

  const onSubmit = () => {
    // in case we get here from a redirect...
    form.trigger();
    // only do the search if the form is valid
    if (form.formState.isValid) {
      // remember the previous form state
      triggerFormDataUpdate(form.getValues());
      /** publish NESS event */
      publishNessEvent({
        level: 'INFO',
        path: '/providers/global/search',
        message: 'Submitting Global Provider Search',
        tags: ['global', 'provider', 'search', 'filter'],
        query: form?.getValues('sources')?.join(',') || 'N/A',
        op: 'GET',
        additionalFields: {
          'user-invoked': true,
          isUIAction: true,
          event: 'GLOBAL-PROVIDER-SEARCH-FILTER',
        },
      });
      navigate('/providers/global/search/results');
    }
  };

  const onClear = () => {
    form.reset(
      {
        identifierTypeCode: null,
        identifierTypeValue: null,
        streetAddress: null,
        firstName: null,
        city: null,
        lastName: null,
        state: null,
        orgName: null,
        postalCode: null,
        sources: form.getValues('sources'), // don't reset the sources
      },
      {
        keepErrors: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      }
    );
    validateFilterProps();
    triggerFormDataUpdate(form.getValues());
  };

  return (
    <React.Fragment>
      <div
        tabIndex={-1}
        ref={myDiv}
        style={{
          padding: '15px',
          margin: '10px',
          maxWidth: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Breadcrumbs
          isDarkMode
          leadingIcon={<IconMaterial icon="home" size="24px" />}
          items={[
            { title: 'Home', href: '/' },
            { title: 'Search Providers', href: '/providers/global/search' },
          ]}
        />
      </div>

      <FormProvider
        state={form}
        onSubmit={onSubmit}
        onError={onError}
        style={{ display: 'flex', flexWrap: 'wrap' }}
      >
        <div
          style={{
            width: '100%',
            padding: '5px',
            margin: '4px',
            display: 'inline-block',
            alignItems: 'center',
            verticalAlign: 'top',
          }}
        >
          <Layout.Space />

          <Alert
            title="No source selected!"
            variant="warning"
            isVisible={form.getValues('sources')?.length === 0}
            onClose={() => {
              // do nothing
            }}
          >
            At least one source must be selected to perform a search!
          </Alert>

          <CheckboxGroup
            model="sources"
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onChange={(_e) => {
              setFilterPropsValid(true);
              setPageUpdated(form.getValues('sources'));
            }}
            validators={{
              required: false,
            }}
          >
            <Layout.Stack>
              <Layout.Group space={50}>
                <Label size="lg">Sources</Label>

                <div
                  data-cy="pgs-ckbox-ffc"
                  id={
                    `${form
                      .getValues('sources')
                      ?.includes(constants.SOURCE_FFC)}`
                      ? constants.SOURCE_FFC
                      : null
                  }
                  style={{
                    display: `${canSearchFfc ? 'inline-block' : 'none'}`,
                  }}
                >
                  <Checkbox
                    label="FFC"
                    value="ffc"
                    model="ffcCheckbox"
                    isChecked={false}
                    // if there are more whats to disable these checkboxes then we'll deffo need a diff approach
                    isDisabled={form
                      .getValues('identifierTypeCode')
                      ?.includes(constants.EIN_ID_TYPE)}
                    onChange={setPageUpdated}
                  />
                </div>

                <div
                  data-cy="pgs-ckbox-optumrx"
                  id={
                    `${form
                      .getValues('sources')
                      ?.includes(constants.SOURCE_OPTUMRX)}`
                      ? constants.SOURCE_OPTUMRX
                      : null
                  }
                  style={{
                    display: `${canSearchOther ? 'inline-block' : 'none'}`,
                  }}
                >
                  <Checkbox
                    label="OptumRx"
                    value="optum-rx"
                    model="optumRxCheckbox"
                    isChecked={false}
                    isDisabled={form
                      .getValues('identifierTypeCode')
                      ?.includes(constants.EIN_ID_TYPE)}
                    onChange={setPageUpdated}
                  />
                </div>

                <div
                  data-cy="pgs-ckbox-cms"
                  id={
                    `${form
                      .getValues('sources')
                      ?.includes(constants.SOURCE_CMS)}`
                      ? constants.SOURCE_CMS
                      : null
                  }
                  style={{
                    display: `${canSearchOther ? 'inline-block' : 'none'}`,
                  }}
                >
                  <Checkbox
                    label="CMS Preclusion"
                    value="cms"
                    model="cmsCheckbox"
                    isChecked={false}
                    isDisabled={false}
                    onChange={setPageUpdated}
                  />
                </div>
                <div
                  data-cy="pgs-ckbox-epim-sanctions"
                  id={
                    `${form
                      .getValues('sources')
                      ?.includes(constants.SOURCE_EPIM)}`
                      ? constants.SOURCE_EPIM
                      : null
                  }
                  style={{
                    display: `${canSearchOther ? 'inline-block' : 'none'}`,
                  }}
                >
                  <Checkbox
                    label="EPIM Sanctions"
                    value="epim-sanctions"
                    model="epimSanctionsCheckbox"
                    isChecked={false}
                    isDisabled={form
                      .getValues('identifierTypeCode')
                      ?.includes(constants.EIN_ID_TYPE)}
                    onChange={setPageUpdated}
                  />
                </div>

                <Layout.Space />
              </Layout.Group>
            </Layout.Stack>
          </CheckboxGroup>

          <Layout.Space />
          <Layout.Space />
        </div>

        {/* ID Types, values and address */}
        <div
          style={{
            width: '100%',
            display: 'inline-block',
            alignItems: 'center',
            verticalAlign: 'top',
          }}
        >
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
            data-cy="pgs-sel-id-type"
          >
            <SelectInput
              label="ID Type"
              placeholder="Select an ID Type"
              model="identifierTypeCode"
              isClearable
              onClear={setPageUpdated}
              isSearchable
              onChange={onIdTypeChange}
              width="98%"
              options={[
                { value: constants.NPI_ID_TYPE, label: constants.NPI_ID_TYPE },
                { value: constants.TIN_ID_TYPE, label: constants.TIN_ID_TYPE },
                {
                  value: constants.MPIN_ID_TYPE,
                  label: constants.MPIN_ID_TYPE,
                },
                { value: constants.EIN_ID_TYPE, label: constants.EIN_ID_TYPE },
              ]}
            />
          </div>
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
          >
            <TextInput
              key="name"
              label="ID Value"
              model="identifierTypeValue"
              width="98%"
              isClearable
              onClear={onIdValueClear}
              // mask='numeric'
              onChange={onIdValueChange}
            />
          </div>
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
          >
            <TextInput
              key="Address"
              label="Address"
              model="streetAddress"
              isClearable
              onClear={setPageUpdated}
              onChange={setPageUpdated}
              width="98%"
            />
          </div>
        </div>

        {/* First Name and City */}
        <div
          style={{
            width: '100%',
            display: 'inline-block',
            alignItems: 'center',
            verticalAlign: 'top',
          }}
        >
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
          >
            <Layout.Space />
          </div>
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
          >
            <TextInput
              key="name"
              label="First Name"
              model="firstName"
              // subText="Min 3 chars"
              isClearable
              onClear={onClearSelection('name')}
              onChange={setPageUpdated}
              width="98%"
            />
          </div>
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
          >
            <TextInput
              key="City"
              label="City"
              model="city"
              isClearable
              onClear={onClearSelection('city')}
              onChange={setPageUpdated}
              width="98%"
            />
          </div>
        </div>

        {/* Last Name, State  */}
        <div
          style={{
            width: '100%',
            display: 'inline-block',
            alignItems: 'center',
            verticalAlign: 'top',
          }}
        >
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
          >
            <Layout.Space />
          </div>
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
          >
            <TextInput
              key="name"
              label="Last Name"
              model="lastName"
              isClearable
              onClear={onClearSelection('lastName')}
              onChange={setPageUpdated}
              width="98%"
            />
          </div>
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
            data-cy="pgs-sel-state"
          >
            <SelectInput
              label="State"
              placeholder="Select a State"
              model="state"
              isClearable
              onClear={onClearSelection('state')}
              isSearchable
              onChange={setPageUpdated}
              options={props.states}
              width="98%"
            />
          </div>
        </div>

        {/* Org Name, Zip Code  */}
        <div
          style={{
            width: '100%',
            display: 'inline-block',
            alignItems: 'center',
            verticalAlign: 'top',
          }}
        >
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
          >
            <Layout.Space />
          </div>
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
          >
            <TextInput
              key="name"
              label="Org Name"
              model="orgName"
              isClearable
              onClear={onClearSelection('orgName')}
              onChange={setPageUpdated}
              width="98%"
            />
          </div>
          <div
            style={{
              width: '20%',
              padding: '5px',
              margin: '4px',
              display: 'inline-block',
              alignItems: 'center',
              verticalAlign: 'top',
            }}
          >
            <TextInput
              key="PostalCode"
              label="ZIP Code"
              model="postalCode"
              width="98%"
              mask="numeric"
              isClearable
              onClear={onClearSelection('postalCode')}
              onChange={setPageUpdated}
              // validators={{
              // 	// required: true,
              // 	// maxLength: {
              // 	// 	value: 5,
              // 	// 	message: 'Max Length 5',
              // 	// },
              // 	pattern: {
              // 		value: /^[0-9]{5}$/,
              // 		message: 'Only the first 5 digits of Zip Code allowed',
              // 	},
              // }}
            />
          </div>
        </div>

        <Layout.Space />

        <div
          style={{
            width: '80%',
            display: 'inline-block',
            marginLeft: 'auto',
            paddingTop: '30px',
            verticalAlign: 'top',
          }}
        >
          <Flex>
            <div style={{ padding: '5px', margin: '1px' }}>
              <Button
                variant="solid"
                type="submit"
                isDisabled={!filterPropsValid}
                onClick={onSubmit}
                data-cy="pgs-btn-search"
              >
                Search
              </Button>
            </div>

            <VisuallyHidden id="reason-disabled">
              The Save buttons are disabled because form fields have invalid
              input.
            </VisuallyHidden>
            <div style={{ padding: '5px', margin: '1px' }}>
              <Button
                variant="outline"
                onClick={onClear}
                data-cy="pgs-btn-clear"
              >
                Clear
              </Button>
            </div>
          </Flex>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};
