/* eslint-disable no-console */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-extraneous-dependencies */
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Router } from '@abyss/web/ui/Router';
import { useCountryStatesSearch } from '@src//hooks/useCountryStatesSearch';
import { HelpPage } from '@src/common/Help';
import { InvalidTokenErrorPage } from '@src/common/InvalidTokenError/InvalidTokenErrorPage';
import { LogoutPage } from '@src/common/Logout';
import { WhatsNewPage } from '@src/common/WhatsNewPage';
import { useSearch } from '@src/hooks/providers/ffc/useSearch';
import { useDecisionCodes } from '@src/hooks/useDecisionCodes';
import { useDecisionReasons } from '@src/hooks/useDecisionReasons';
import { useProviderIdentifierTypesSearch } from '@src/hooks/useProviderIdentifierTypesSearch';
import { useProviderTypesSearch } from '@src/hooks/useProviderTypesSearch';
import { useUserSearch } from '@src/hooks/useUserSearch';
import { AdminPage } from '@src/routes/admin';
import { HomePage } from '@src/routes/home';
import { FFCProviderCreatePage } from '@src/routes/provider/ffc/create';
import { ManageFFCCasesPage } from '@src/routes/provider/ffc/manage';
import { LoadingPage } from '@src/routes/provider/ffc/manage/LoadingPage';
import { FFCProviderUpdatePage } from '@src/routes/provider/ffc/update';
import { Filter } from '@src/routes/provider/global/search/Filter';
import { Results } from '@src/routes/provider/global/search/Results';
import React, { useEffect, useState } from 'react';
import { v4 as someRandomState } from 'uuid';
import { useLocation } from 'react-router-dom';
import { useAuthState } from '@src/app/auth/auth.state';
import { AppBody } from '../app/AppBody';
import { utils } from './provider/common/dataGridUtils';
import { GoHome } from './GoHome';
import { publishPageHitToNess } from '../common/logging/publishNessEvent';

const navItems = [
  {
    title: 'FFC Providers',
    isDisabled: false,
    subItems: [
      {
        title: 'Search',
        after: <IconMaterial icon="search" size={16} />,
        href: '/home',
      },
      {
        title: 'Create',
        after: <IconMaterial icon="create" size={16} />,
        href: '/home',
      },
    ],
  },
  {
    title: 'Logout',
    href: '/logout',
    // before: <IconMaterial icon="info" size={16} />,
    after: <IconMaterial icon="logout" size={16} />,
  },
];
export const Routes = () => {
  const { canReadFfc, canWriteFfc } = useAuthState(
    (state) => state.permissions
  );
  // capture what page we happen to be on
  const location = useLocation();
  useEffect(() => {
    // when the user changes page then we should fire the ness log
    publishPageHitToNess(location.pathname).catch((reason) =>
      console.error(reason?.message)
    );
  }, [location.pathname]);

  const [genRandomState, setGenRandomState] = useState(someRandomState());
  const [selectOptionsPropsState, setSelectOptionsPropsState] = useState({});

  const allStatePropsLoaded = (props) => {
    return (
      'decisionCodes' in props &&
      'decisionReasons' in props &&
      'providerTypes' in props &&
      'states' in props &&
      'providerIdentifierTypes' in props
    );
  };

  const [, getDecisionCodes] = useDecisionCodes({
    onCompleted: (response) => {
      try {
        const dc = response?.data?.decisionCodes?.toSorted().map((dataItem) => {
          return {
            value: dataItem.code,
            label: dataItem.name,
          };
        });

        setSelectOptionsPropsState((options) => {
          return {
            ...options,
            decisionCodes: dc,
          };
        });
      } catch (e) {
        console.log('decision-codes:Exception:%s', e);
      }
    },
  });

  const [, getDecisionReasons] = useDecisionReasons({
    onCompleted: (response) => {
      try {
        const dr = response?.data?.decisionReasons
          ?.toSorted()
          .map((dataItem) => {
            return {
              value: dataItem.code,
              label: dataItem.name,
            };
          });
        utils.setAllowedReasonsCodes(
          'ffc',
          dr.map((value) => value.value)
        );
        setSelectOptionsPropsState((options) => {
          return {
            ...options,
            decisionReasons: dr,
          };
        });
      } catch (e) {
        console.log('decision-reasons:Exception:%s', e);
      }
    },
  });

  const [, getProviderTypes] = useProviderTypesSearch({
    onCompleted: (response) => {
      try {
        const ptypes = response.data.providerTypes?.content
          ?.toSorted()
          .map((dataItem) => {
            return {
              value: dataItem.code,
              label: dataItem.type,
            };
          });

        setSelectOptionsPropsState((options) => {
          return {
            ...options,
            providerTypes: ptypes,
          };
        });
      } catch (e) {
        console.log('types:Exception:%s', e);
      }
    },
  });

  const [, getCountryStates] = useCountryStatesSearch({
    onCompleted: (response) => {
      try {
        const s = response?.data?.countryStates?.content
          ?.toSorted()
          .map((dataItem) => {
            return {
              value: dataItem.code,
              label: dataItem.name,
            };
          });
        setSelectOptionsPropsState((options) => {
          return {
            ...options,
            states: s,
          };
        });
      } catch (e) {
        console.log('states:Exception:%s', e);
      }
    },
  });

  const [isLoadingProviders, setIsLoadingProviders] = useState(true);
  const [providersLoaded, setProvidersLoaded] = useState(false);
  const [providers, setProviders] = useState([]);
  const [, getProviderSearch] = useSearch({
    onCompleted: (response) => {
      setProviders(response.data.search.data);

      setProvidersLoaded(true);
      setIsLoadingProviders(false);
    },
    onError: (err) => {
      console.error(err);
      setProviders([]);
      setProvidersLoaded(true);
      setIsLoadingProviders(false);
    },
  });

  const [, getProviderIdentifierTypes] = useProviderIdentifierTypesSearch({
    onCompleted: (response) => {
      try {
        // for now, we'll check against these but ideally the api should filter out non ffc allowed types
        const FFC_ALLOWED_TYPES = ['MPIN', 'NPI', 'TIN'];
        const pits = response.data?.providerIdentifierTypes
          ?.toSorted()
          .filter((t) => FFC_ALLOWED_TYPES.includes(t.code))
          .map((dataItem) => {
            return {
              value: dataItem.code,
              label: dataItem.code,
            };
          });
        setSelectOptionsPropsState((options) => {
          return {
            ...options,
            providerIdentifierTypes: pits,
          };
        });
      } catch (e) {
        console.error('provider-identifier-types:Exception:%s', e);
      }
    },
  });

  const [user, setUser] = useState({});
  const [, getUserSearch] = useUserSearch({
    onCompleted: (response) => {
      setUser({
        ...response.data?.user,
        loggedIn: true,
      });
    },
    onError: (err) => {
      console.error(err);
      setUser({
        loggedIn: false,
      });
    },
  });

  const [globalSearchFilterProps, setGlobalSearchFilterProps] = useState(null);
  const tiggerUpdatedGlobalSearchFilterProps = (formData) => {
    setGlobalSearchFilterProps(formData);
  };

  const tiggerSearch = () => {
    // eslint-disable-next-line arrow-body-style
    setGenRandomState(someRandomState());
  };
  /**
    when the user edits/updates a provider this is called so that we update the state rather
    than making a full-blown call-out to backend again */
  const tiggerUpdatedProvider = (updatedProvider) => {
    // eslint-disable-next-line arrow-body-style
    // find where in the collection the updated provider sits
    const index = providers.findIndex((p) => p.id === updatedProvider.id);
    // if we can't find it then do a call-out to backend
    if (index === -1) {
      // this will force the state to change and call the `useEffect()` which will call the search api
      setGenRandomState(someRandomState());
    } else {
      // ok, so we have an existing provider, now update it
      providers.splice(index, 1, {
        id: updatedProvider.id,
        dollarBills: updatedProvider.dollarBills,
        firstName: updatedProvider.firstName,
        lastName: updatedProvider.lastName,
        middleInitial: updatedProvider.middleInitial,
        notes: updatedProvider.notes,
        orgName: updatedProvider.orgName,
        presentedDate: updatedProvider.presentedDate,
        providerTypeCode: updatedProvider.providerTypeCode,
        requestedDecisionCode: updatedProvider.requestedDecisionCode,
        tipSource: updatedProvider.tipSource,
      });
      // and set the state
      setProviders(providers);
    }
  };

  useEffect(() => {
    // only do this once
    getDecisionCodes();
    getDecisionReasons();
    getProviderTypes();
    getProviderIdentifierTypes();
    getCountryStates();
    getUserSearch();
  }, []);

  useEffect(() => {
    if (canReadFfc || canWriteFfc) {
      setProvidersLoaded(false);
      setIsLoadingProviders(true);
      getProviderSearch({
        variables: {
          pageNumber: 0,
          pageSize: 3000,
        },
      });
    } else {
      setProvidersLoaded(true);
      setIsLoadingProviders(false);
    }
  }, [canReadFfc, canWriteFfc, genRandomState]);

  const renderWithAccess = () => {
    return (
      <Router.Routes title="dFEDS">
        {/* { canViewFfc && (<Router.Route path="/" element={<ErrorLoginPage />}   />)} */}

        {/* { isLoggedIn ? null : (<Router.Route path="/" element={<ErrorLoginPage />}   />)} */}

        <Router.Route
          path="/"
          element={<AppBody navItems={navItems} user={user} />}
        >
          <Router.Route
            path="/token-error"
            element={<InvalidTokenErrorPage />}
          />
          <Router.Route path="/help" element={<HelpPage />} />
          <Router.Route path="/whats-new" element={<WhatsNewPage />} />
          canAdmin && <Router.Route path="/admin" element={<AdminPage />} />
          {/* Home page */}
          <Router.Route path="/logout" element={<LogoutPage />} />
          {/* Home page */}
          <Router.Route path="/" element={<HomePage />} />
          {/* The global search */}
          <Router.Route
            path="/providers/global/search"
            element={
              <Filter
                previousFormData={globalSearchFilterProps}
                triggerFormDataUpdate={tiggerUpdatedGlobalSearchFilterProps}
                props={selectOptionsPropsState}
              />
            }
          />
          <Router.Route
            path="/providers/global/search/results"
            element={
              <Results
                filterData={globalSearchFilterProps}
                props={selectOptionsPropsState}
              />
            }
          />
          {/* manage FFC Provider page - if a refresh is called then we may need to display a loading sign */}
          {
            // (canReadFfc || canWriteFfc ) && (
            providersLoaded ? (
              <Router.Route
                path="/providers/manage"
                element={
                  <ManageFFCCasesPage
                    providers={providers}
                    selectOptionsProps={selectOptionsPropsState}
                  />
                }
              />
            ) : (
              <Router.Route
                path="/providers/manage"
                element={
                  <LoadingPage
                    title="Loading Providers"
                    message="Please wait while we load your providers list......"
                    isLoading={isLoadingProviders}
                  />
                }
              />
            )
            // )
          }
          {/* FFC Provider create page - the grid doesn't remember the states between client refresh hence we need to wait for states */}
          {allStatePropsLoaded(selectOptionsPropsState) ? (
            <Router.Route
              path="/providers/create"
              element={
                <FFCProviderCreatePage
                  triggerSearch={tiggerSearch}
                  tiggerUpdatedProvider={tiggerUpdatedProvider}
                  selectOptionsProps={selectOptionsPropsState}
                />
              }
            />
          ) : (
            <Router.Route
              path="/providers/create"
              element={
                <LoadingPage
                  title="Refreshing details"
                  message="Please wait while we refresh the screeen..."
                  isLoading={!allStatePropsLoaded(selectOptionsPropsState)}
                />
              }
            />
          )}
          {/* FFC Provider edit/update page */}
          {allStatePropsLoaded(selectOptionsPropsState) ? (
            <Router.Route
              path="/providers/edit/:id"
              element={
                <FFCProviderUpdatePage
                  tiggerUpdatedProvider={tiggerUpdatedProvider}
                  selectOptionsProps={selectOptionsPropsState}
                />
              }
            />
          ) : (
            <Router.Route
              path="/providers/edit/:id"
              element={
                <LoadingPage
                  title="Refreshing details"
                  message="Please wait while we refresh the screeen..."
                  isLoading={!allStatePropsLoaded(selectOptionsPropsState)}
                />
              }
            />
          )}
          {/* <Router.Route
            path={RouteUrl.eventsSearch}
            element={<EventSearchPage />}
          /> */}
          {/* if the path is unknown to us then go back to the home page */}
          {/* <Router.Route path="/providers/manage" action={(arg) => Promise.resolve(navigate("/providers/manage"))} /> */}
          <Router.Route path="/*" element={<GoHome />} />
          {/* FFC Provider search page */}
          {/* <Router.Route path="/disallowed-entities/providers/ffc/search" element={<FFCProviderSearchPage providerTypes={types} states={states}/>} /> */}
        </Router.Route>
      </Router.Routes>
    );
  };

  return renderWithAccess();
};
