/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable arrow-body-style */
import { isClient } from '@src/common/util/isClient';
import { create } from 'zustand';
import { defaultUser } from './defaultUser';
import { fetchAuthStatus } from './fetchAuthStatus';
import { fetchUser } from './fetchUser';
import { defaultPermissions, toPermissions } from './permissionUtils';
import { AuthStore } from './types';
import { sessionCountdown } from './sessionCountdown';
import { getAuthorizedNavLinks } from './getAuthorizedNavLinks';

export const useAuthState = create<AuthStore>(() => ({
  authStatusKnown: false,
  authenticated: false,
  permissions: defaultPermissions,
  secureGroups: [],
  validToken: false,
  user: { ...defaultUser },
  authorizedNavLinks: [],
  error: false,
}));

async function init() {
  const [authResponse, userResponse] = await Promise.all([
    fetchAuthStatus(),
    fetchUser(),
  ]);

  const { authenticated, roles, secureGroups, validToken, error } =
    authResponse;

  const permissions = toPermissions(roles);

  useAuthState.setState({
    authStatusKnown: true,
    authenticated,
    permissions,
    secureGroups,
    validToken,
    user: userResponse.payload.data.user,
    authorizedNavLinks: getAuthorizedNavLinks(permissions),
    error,
  });

  if (authenticated) {
    sessionCountdown.initCountdown();
  }
}

if (isClient()) {
  init();
}
