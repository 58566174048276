import { useQuery } from '@abyss/web/hooks/useQuery';
import GetProviderGlobalSearch from './GetProviderGlobalSearch.gql';

export const useGetProviderGlobalSearch = (options) => {
  return useQuery(GetProviderGlobalSearch, {
    ...options,
    url: '/api/graphql',
    headers:{
      "Accept-Encoding": 'gzip, deflate, br',
    }
  });
};
