import { useQuery } from '@abyss/web/hooks/useQuery';
// @ts-ignore
import CreateFFCProvider from './CreateFFCProvider.gql';

export const useCreateFFCProvider = (options) => {
  return useQuery(CreateFFCProvider, {
    ...options,
    url: '/api/graphql',
  });
};
