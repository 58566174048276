/* eslint-disable arrow-body-style */
import React from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { useAuthState } from '@src/app/auth/auth.state';

export const HomePage = () => {
  const authorizedNavLinks = useAuthState((state) => {
    return state.authorizedNavLinks;
  });

  return (
    <div id="homePageContent">
      <div>
        <Heading textAlign="center">dFEDS</Heading>
        <Heading offset={1} textAlign="center">
          Digital Fraud Engineering Detection Services
        </Heading>
      </div>

      <div id="navigationGrid">
        {authorizedNavLinks.map(({ id, href, title, icon }) => (
          <Link
            key={id}
            className="trayLink"
            href={href}
            data-testid={`tile-tray-link-${id}`}
            data-cy={`tile-tray-link-${id}`}
            variant="custom"
          >
            <IconMaterial {...icon} />
            <div className="trayTitle">{title}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};
