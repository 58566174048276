/* eslint-disable @typescript-eslint/no-use-before-define */
import { getFetch } from './fetchProvider';
import { AjaxResponse } from './types';

interface Props<ResponseType> {
  defaultResponseData: ResponseType;
  input: RequestInfo;
  init?: RequestInit;
}

export async function ajax<ResponseType>(
  defaultResponseData: ResponseType,
  input: RequestInfo,
  init?: RequestInit
): Promise<AjaxResponse<ResponseType>> {
  return handleCall(init?.method || 'post', {
    defaultResponseData,
    input,
    init,
  });
}

async function handleCall<ResponseType>(
  method: string,
  { input, init, defaultResponseData }: Props<ResponseType>
) {
  try {
    const response = await getFetch()(input, getDefaultInit(method, init));
    if (!response.ok || response.status === 204) {
      return {
        payload: defaultResponseData,
        status: response.status,
        success: response.ok,
      };
    }
    const json = await response.json();
    return {
      payload: {
        ...defaultResponseData,
        ...json,
      },
      status: response.status,
      success: response.ok,
    };
  } catch (e) {
    // TODO: - log
    return {
      payload: defaultResponseData,
      status: 506,
      success: false,
    };
  }
}

function getDefaultInit(method: string, init?: RequestInit): RequestInit {
  return {
    ...init,
    method,
    headers: {
      'Content-Type': 'application/json',
      ...init?.headers,
    },
  };
}
