/* eslint-disable @typescript-eslint/no-use-before-define */
import { PermissionNames, Permissions } from './types';

export const defaultPermissions: Permissions = {
  canLogin: false,
  canReadFfc: false,
  canWriteFfc: false,
  canSearchFfc: false,
  canSearchCms: false,
  canSearchEpim: false,
  canSearchOptumRx: false,
  canSearchEvents: false,
  admin: false,
} as const;

export const toPermissions = (permissions: string[]): Permissions => {
  const {
    canLogin,
    canReadFfc,
    canWriteFfc,
    canSearchFfc,
    canSearchCms,
    canSearchEpim,
    canSearchOptumRx,
    canSearchEvents,
    admin,
  } = PermissionNames;

  const perms = { ...defaultPermissions };
  perms.canLogin = inc(canLogin);
  perms.canReadFfc = inc(canReadFfc);
  perms.canWriteFfc = inc(canWriteFfc);
  perms.canSearchFfc = inc(canSearchFfc);
  perms.canSearchCms = inc(canSearchCms);
  perms.canSearchEpim = inc(canSearchEpim);
  perms.canSearchOptumRx = inc(canSearchOptumRx);
  perms.canSearchEvents = inc(canSearchEvents);
  perms.admin = inc(admin);

  return perms;

  function inc(name: PermissionNames) {
    return permissions.includes(name);
  }
};
