/* eslint-disable no-console */
import { useForm } from '@abyss/web/hooks/useForm';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Breadcrumbs } from '@src/abyss/web/ui/Breadcrumbs';
import { useUpdateRoles } from '@src/hooks/admin/changeRoles';
import { useHasRole } from '@src/hooks/useHasRole';
import { utils } from '@src/routes/provider/common/dataGridUtils';
import React, { useState } from 'react';
import { useWhoLoggedOn } from '@src/hooks/admin/whoLoggedOn';

const NoPageContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
});

export const AdminPage = () => {
  const [admin, setAdmin] = useState(false);
  const [, isAdmin] = useHasRole({
    onCompleted: (response) => {
      // unpack and see if user has what roles
      const roles = Object.freeze(response?.data?.hasRole);
      setAdmin(roles.admin);
    },
    onError: (err) => {
      console.error(err);
      setAdmin(false);
    },
  });
  useState(() => {
    // call the middleware to see if the user can do admin things
    return isAdmin({
      variables: {
        roles: Object.freeze(['admin']),
      },
    });
  });

  const [users, setUsers] = useState([]);
  const [msids, setMsids] = useState([]);
  const [currentUser, setCurrentUser] = useState('');
  const [, getWhoLoggedOn] = useWhoLoggedOn({
    onCompleted: (response) => {
      // unpack and see all the currently logged in users and their associated permissions
      const loggedIn = Object.freeze(response?.data?.whoLoggedOn);
      setUsers(loggedIn);
      const msIds = loggedIn.map((user) => {
        return { value: user?.msid, label: user?.msid };
      });
      console.log(`msid = ${msIds}`);
      setMsids(msIds);
      setCurrentUser(msIds.length !== 0 ? msIds[0]?.label : 'N/A');
    },
    onError: (err) => {
      const title = 'Unexpected error!';
      const message = err?.message || 'Unexpected error!';
      utils.popupMsg(title, message);
    },
  });

  const [, setRefresh] = useState(false);

  const [, updateUsersRoles] = useUpdateRoles({
    onCompleted: () => {
      setRefresh(true);
      getWhoLoggedOn();
    },
    onError: (err) => {
      console.error(err);
      setRefresh(false);
    },
  });

  const form = useForm({});

  const onSubmit = (data) => {
    console.log('data', JSON.stringify(data));
    console.log(
      `checkbox-form values = ${JSON.stringify(
        form.getValues('checkbox-form')
      )}`
    );
    updateUsersRoles({
      variables: {
        msid: currentUser,
        roles: [...form.getValues('checkbox-form'), 'admin'],
      },
    });
  };

  const onInputChange = (msid) => {
    setCurrentUser(msid);
    const user =
      users?.find((u) => {
        return u?.msid === msid;
      }) || [];

    const resetWith = {
      msid: currentUser,
      'checkbox-form': user.roles,
    };
    form.reset(resetWith, {
      keepErrors: false,
      keepDirty: false,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    });
  };

  const [group, setGroup] = useState('dFEDS_General_Provider_Read');

  const onGroupChange = (secureGrpValuesAsString) => {
    console.log('secureGrpName:', JSON.stringify(secureGrpValuesAsString));
    const roles = JSON.parse(secureGrpValuesAsString);
    console.log('roles:', JSON.stringify(roles));
    setGroup(secureGrpValuesAsString);

    const resetWith = {
      'checkbox-form': roles.slice(1),
    };
    form.reset(resetWith, {
      keepErrors: false,
      keepDirty: false,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    });
  };

  React.useEffect(() => {
    getWhoLoggedOn();
  }, []);

  const render = () => {
    return (
      <FormProvider state={form} onSubmit={onSubmit}>
        <div
          tabIndex={-1}
          style={{
            padding: '15px',
            margin: '10px',
            maxWidth: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Breadcrumbs
            isDarkMode
            leadingIcon={<IconMaterial icon="home" size="24px" />}
            items={[
              { title: 'Home', href: '/' },
              { title: 'Admin', href: '/admin' },
            ]}
          />
        </div>

        <NoPageContainer>
          <br />
          <br />
          <h3>dFEDS Admin Page</h3>
          <br />
          <br />
          <br />
          <br />
        </NoPageContainer>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '50%',
          }}
        >
          <div
            style={{
              flex: '2',
              padding: '10px',
            }}
            data-cy="admin-sel-msid"
          >
            <SelectInput
              label="Select MS-ID"
              placeholder="Pick a user and change their role(s)"
              model="msids"
              isSearchable
              width={500}
              options={msids}
              onChange={onInputChange}
            />
            <br />
            <br />
          </div>

          <div
            style={{
              flex: '2',
              padding: '10px',
            }}
            data-cy="admin-sel-dfeds-group"
          >
            <SelectInput
              label="Pick a dFEDS secure group"
              placeholder="Pick a secure group"
              isSearchable
              model="group"
              width={500}
              value={group}
              options={[
                {
                  value: JSON.stringify([
                    'dFEDS_FFC_Provider_Read',
                    'login',
                    'search-ffc',
                    'read-ffc',
                  ]),
                  label: 'dFEDS_FFC_Provider_Read',
                },
                {
                  value: JSON.stringify([
                    'dFEDS_FFC_Provider_Admin',
                    'login',
                    'search-ffc',
                    'read-ffc',
                    'write-ffc',
                  ]),
                  label: 'dFEDS_FFC_Provider_Admin',
                },
                {
                  value: JSON.stringify([
                    'dFEDS_General_Provider_Read',
                    'login',
                    'search-optumrx',
                    'search-epim',
                    'search-cms',
                    'search-events',
                  ]),
                  label: 'dFEDS_General_Provider_Read',
                },
                {
                  value: JSON.stringify([
                    'dFEDS_Provider_Superuser_Read',
                    'login',
                    'search-ffc',
                    'search-optumrx',
                    'search-epim',
                    'search-cms',
                    'read-ffc',
                    'search-events',
                  ]),
                  label: 'dFEDS_Provider_Superuser_Read',
                },
                {
                  value: JSON.stringify([
                    'dFEDS_Provider_Superuser_Admin',
                    'login',
                    'search-ffc',
                    'search-optumrx',
                    'search-epim',
                    'search-cms',
                    'read-ffc',
                    'write-ffc',
                    'search-events',
                  ]),
                  label: 'dFEDS_Provider_Superuser_Admin',
                },
              ]}
              onChange={onGroupChange}
            />
            <br />
            <br />
          </div>
        </div>

        <CheckboxGroup
          label={`${currentUser} current roles`}
          model="checkbox-form"
          onChange={(e) => {
            console.log(`e = ${JSON.stringify(e)}`);
          }}
        >
          <br />
          <Checkbox
            label="Can login to UI"
            value="login"
            model="login"
            id="login-id"
          />
          <Checkbox
            label="Can search FFC providers"
            value="search-ffc"
            model="searchFfc"
          />
          <Checkbox
            label="Can search CMS providers"
            value="search-cms"
            model="searchCms"
          />
          <Checkbox
            label="Can search EPIM providers"
            value="search-epim"
            model="searchEpim"
          />
          <Checkbox
            label="Can search Optum RX providers"
            value="search-optumrx"
            model="searchOptumrx"
          />
          <Checkbox
            label="Can view FFC provides"
            value="read-ffc"
            model="viewFfc"
          />
          <Checkbox
            label="Can view, create and edit FFC providers"
            value="write-ffc"
            model="createFfc"
          />
          <Checkbox
            label="Can search events"
            value="search-events"
            model="searchEvents"
          />
          {/* <Checkbox label="admin" model='admin' isChecked={true} isDisabled={true} /> */}
        </CheckboxGroup>

        <Button
          type="submit"
          style={{ marginTop: '15px' }}
          data-cy="admin-btn-reset-roles"
        >
          {`Changes ${currentUser} Roles`}
        </Button>
      </FormProvider>
    );
  };

  const renderNoAdmin = () => {
    return (
      <NoPageContainer>
        <br />
        <br />
        <h3>dFEDS Admin Page</h3>
      </NoPageContainer>
    );
  };

  return admin ? render() : renderNoAdmin();
};
