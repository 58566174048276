import { useQuery } from '@abyss/web/hooks/useQuery';
import OptumRxGlobalSearch from './GetOptumRxGlobalSearch.gql';

export const useGetOptumRxGlobalSearch = (options) => {
  return useQuery(OptumRxGlobalSearch, {
    ...options,
    url: '/api/graphql',
    headers:{
      "Accept-Encoding": 'gzip, deflate, br',
    }
  });
};
