import { useQuery } from '@abyss/web/hooks/useQuery';
import GetLogout from './GetLogout.gql';

export const useLogout = (options) => {
  return useQuery(GetLogout, {
    ...options,
    url: '/api/graphql',
    accessor: 'data',
    requestPolicy: 'no-cache',
  });
};
