import React from 'react';
import { Card } from '@src/abyss/web/ui/Card';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { styled } from '@abyss/web/tools/styled';

const CenterLoading = styled('div', {
  display: 'flex',
  marginTop: '15%',
  height: 250,
});

// TODO Let's get it more centred
export const LoadingPage = ({ title, message, isLoading }) => {
  return (
    <React.Fragment>
      <CenterLoading>
        <LoadingOverlay
          loadingTitle={title}
          loadingMessage={message}
          statusTitle="Loading Complete..."
          statusMessage="All providers have been loaded."
          ariaLoadingLabel="This is the ariaLoadingLabel prop."
          isLoading={isLoading}
          isDismissable
          loadingIcon={
            <IconMaterial color="$primary1" icon="upload_file" size="35px" />
          }
        >
          <Card
            css={{
              'abyss-card-root': {
                height: 250,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '$gray4',
              },
            }}
          />
        </LoadingOverlay>
      </CenterLoading>
    </React.Fragment>
  );
};
