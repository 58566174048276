/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
import React, { useEffect, useState, useRef } from 'react';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Button } from '@abyss/web/ui/Button';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { IProviderHeading } from '@src/routes/provider/common/dataGridUtils';
import { Breadcrumbs } from '@src/abyss/web/ui/Breadcrumbs';
import { formatCurrency } from '@src/common/util/formatter';
import { useAuthState } from '@src/app/auth/auth.state';
import { constants } from '../../common/constants';

// TODO; we need to create tests

export const ManageFFCCasesPage = ({ providers, selectOptionsProps }) => {
  const authStatusKnown = useAuthState((state) => state.authStatusKnown);
  const { canReadFfc, canWriteFfc } = useAuthState(
    (state) => state.permissions
  );
  const [loadingMsg] = useState('Loading providers...');

  const myDiv = useRef();

  const providerToColumn = (provider: IProviderHeading) => {
    return {
      id: `${provider.id}`,
      firstName: provider.firstName,
      lastName: provider.lastName,
      orgName: provider.orgName,
      providerTypeCode: selectOptionsProps?.providerTypes?.find(
        (pair) => pair.value === provider.providerTypeCode
      )?.label,
      requestedDecisionCode: selectOptionsProps?.decisionCodes?.find(
        (pair) => pair.value === provider.requestedDecisionCode
      )?.label,
      presentedDate: provider.presentedDate,
      tipSource: provider.tipSource,
      dollarBills: `$${formatCurrency(provider.dollarBills)}`,
      col11: true,
    };
  };

  const loadData = () => {
    return providers?.map((provider) => providerToColumn(provider)) || [];
  };

  // TODO need a better way to get the results
  const data = React.useMemo(() => [...loadData()], [providers]);

  const dataTableProps = useDataTable({
    initialData: data,
    initialColumns: [], // columns,
    showSelection: false,
    showPagination: true,
    showGlobalFilter: true,
    showTableSettings: false,
    showFullscreenButton: false,
    highlightRowOnHover: true,
    showDownloadButton: true,
    showFilterDataset: true,
    showColumnVisibilityConfig: true,
    defaultTableSettings: {},
    pageSizeOptions: constants.DEFAULT_PAGE_SIZE_OPTIONS,
    // additionalPaginationText: '(Can insert extra text here)',
    uniqueStorageId: 'Manage-FFC-Cases-data-table',
    onColumnVisibilityClose: (cols) =>
      console.log('columns', cols, dataTableProps.columnMgmt.allColumns),
    onColumnVisibilityOpen: (cols) =>
      console.log('columns', cols, dataTableProps.columnMgmt.allColumns),
  });

  const { navigate } = useRouter();
  const { getLocation } = useRouter();
  const location = getLocation();

  useEffect(() => {
    if (authStatusKnown && !canReadFfc) {
      navigate('/');
    }
  }, [authStatusKnown, canReadFfc]);

  useEffect(() => {
    dataTableProps.setColumns(
      [
        {
          Header: 'Fraud Case Key',
          // isRowHeader: true,
          accessor: 'id',
          canToggleVisibilty: false,
          canReorderColumn: false,
          isHiddenByDefault: false,
          disableSortBy: false,
          hiddenDefaultFilters: ['greater'],
          maxWidth: 150,
          customAPIFilters: [
            {
              value: 'includes',
              label: 'Includes',
            },
          ],
          Cell: ({ value }) => (
            <Link href={`/providers/edit/${value}`}>{value}</Link>
          ),
        },
        {
          Header: 'First Name',
          accessor: 'firstName',
          canToggleVisibilty: false,
          isHiddenByDefault: false,
          disableSortBy: false,
          canReorderColumn: false,
          maxWidth: 150,
          hiddenDefaultFilters: ['greater'],
          customAPIFilters: [
            {
              value: 'includes',
              label: 'Includes',
            },
          ],
        },
        {
          Header: 'Last Name',
          accessor: 'lastName',
          maxWidth: 150,
          canToggleVisibilty: false,
          canReorderColumn: false,
          disableSortBy: false,
          isHiddenByDefault: false,
          label: 'Table Data 3',
          // Cell: ({value}) => {
          // 	return <Link href="/disallowed-entities/providers/managed/ffc/create">{value}</Link>;
          // },
        },
        {
          Header: 'Org Name',
          accessor: 'orgName',
          maxWidth: 250,
          canToggleVisibilty: false,
          disableSortBy: false,
          isHiddenByDefault: false,
          canReorderColumn: false,
        },
        {
          Header: 'Provider Type',
          accessor: 'providerTypeCode',
          canToggleVisibilty: true,
          isHiddenByDefault: false,
          disableSortBy: false,
          maxWidth: 255,
          canReorderColumn: true,
        },
        {
          Header: 'Requested Decision',
          accessor: 'requestedDecisionCode',
          canToggleVisibilty: true,
          isHiddenByDefault: false,
          disableSortBy: false,
          maxWidth: 150,
          canReorderColumn: true,
        },
        {
          Header: 'Presented Date',
          accessor: 'presentedDate',
          canToggleVisibilty: true,
          isHiddenByDefault: false,
          disableSortBy: false,
          maxWidth: 255,
          canReorderColumn: true,
        },
        {
          Header: 'Source of Tip',
          accessor: 'tipSource',
          canToggleVisibilty: true,
          isHiddenByDefault: false,
          disableSortBy: false,
          maxWidth: 255,
          canReorderColumn: true,
        },
        {
          Header: 'Billed Amount',
          accessor: 'dollarBills',
          canToggleVisibilty: true,
          isHiddenByDefault: false,
          disableSortBy: false,
          maxWidth: 150,
          canReorderColumn: true,
        },
      ],
      true
    );

    myDiv.current.focus();
  }, [location, providers, canReadFfc, canWriteFfc]);

  return (
    <React.Fragment>
      <div
        tabIndex={-1}
        ref={myDiv}
        style={{
          padding: '15px',
          margin: '10px',
          maxWidth: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {/* We look at how Volcan use this but this is only temp for now */}
        <Breadcrumbs
          // divider={'...'}
          isDarkMode
          leadingIcon={<IconMaterial icon="home" size="24px" />}
          items={[
            { title: 'Home', href: '/' },
            { title: 'Manage FFC Cases', href: '/providers/manage' },
          ]}
        />
      </div>

      <LoadingOverlay
        loadingTitle="Providers"
        loadingMessage={loadingMsg}
        ariaLoadingLabel="This is the ariaLoadingLabel prop."
        isLoading={!authStatusKnown}
      />

      <div
        style={{
          padding: '5px',
          marginBottom: '1rem',
          width: '300px',
          display: `${canWriteFfc ? 'inline-block' : 'none'}`,
        }}
      >
        <Button
          variant="outline"
          onClick={() => {
            navigate('/providers/create');
          }}
          data-cy="mfc-btn-create"
        >
          New Case
        </Button>
      </div>

      <div>
        {/* { ffcView && <DataTable tableState={dataTableProps}/> } */}
        <DataTable tableState={dataTableProps} hideTitleHeader />
      </div>
    </React.Fragment>
  );
};
