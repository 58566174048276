import { useQuery } from '@abyss/web/hooks/useQuery';
import EpimSanctionsGlobalSearch from './EpimSanctionsGlobalSearch.gql';

export const useGetEpimSanctionsGlobalSearch = (options) => {
  return useQuery(EpimSanctionsGlobalSearch, {
    ...options,
    url: '/api/graphql',
    headers:{
      "Accept-Encoding": 'gzip, deflate, br',
    }
  });
};
