import { useQuery } from '@abyss/web/hooks/useQuery';
// @ts-ignore
import UpdateProvider from './UpdateProvider.gql';

export const useUpdateProvider = (options) => {
  return useQuery(UpdateProvider, {
    ...options,
    url: '/api/graphql',
  });
};
