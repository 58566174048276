export const RouteUrl = {
  admin: '/admin',
  providerSearch: '/providers/global/search',
  providerManage: '/providers/manage',
  providerCreate: '/providers/create',
  eventsSearch: '/events/search',
  help: '/help',
  releaseNotes: '/whats-new',
  logout: '/logout',
};

export function isRoute(route: string) {
  return window.location.pathname.endsWith(route);
}
