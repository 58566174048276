/* eslint-disable arrow-body-style */
import React from 'react';
import { createTheme } from '@abyss/web/tools/theme';
import { createRouter } from '@abyss/web/tools/createRouter';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { PageBody } from '@abyss/web/ui/PageBody';
import { UnauthorizedErrorPage } from '@src/common/Error';
import { LoadingPage } from '@src/routes/provider/ffc/manage/LoadingPage';
import { InvalidTokenErrorPage } from '@src/common/InvalidTokenError';
import { Routes } from './routes';
import './assets/css/styles.css';
import { useAuthState } from './app/auth/auth.state';

const theme = createTheme('uhg');
const router = createRouter(Routes);

export const browser = () => {
  const authStatusKnown = useAuthState((state) => state.authStatusKnown);
  const validToken = useAuthState((state) => state.validToken);
  const error = useAuthState((state) => state.error);
  const { canLogin } = useAuthState((state) => state.permissions);

  if (!authStatusKnown) {
    return (
      <LoadingPage
        isLoading
        message="Please hang on in there while we load up your permissions..."
        title="Loading Your Permissions"
      />
    );
  }

  if (error) {
    return (
      <PageBody>
        <div style={{ marginTop: '5rem' }}>
          Error loading permissions, please refresh the page
        </div>
      </PageBody>
    );
  }

  if (!validToken) {
    return (
      <div style={{ marginTop: '5rem' }}>
        <InvalidTokenErrorPage />
      </div>
    );
  }

  if (!canLogin) {
    return (
      <PageBody>
        <div style={{ marginTop: '5rem' }}>
          <UnauthorizedErrorPage />
        </div>
      </PageBody>
    );
  }

  return <AbyssProvider theme={theme} router={router} />;
};
