import React from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Link } from '@abyss/web/ui/Link';
import { config } from '@abyss/api/tools/config';

export const UnauthorizedErrorPage = () => {
  return (
    <React.Fragment>
      <PageBody>
        <br />
        <Heading>Access denied</Heading>
        <p>
          <br />
          You do not seem to have the correct permissions to log into our{' '}
          <strong>dFEDS</strong> application.
          <br />
          <br />
          <br />
          Please submit a <Link href="https://secure.uhc.com/">
            Secure
          </Link>{' '}
          request in order to get access.
          <br />
        </p>
        <br />
        <br />
        {/* <Heading offset={2} textAlign="center"> */}
        However, if you have the necessary permissions, your security token
        might have expired.
        <br />
        <Link href={`${config('LOG_BACK_IN_URL')}`} openNewWindow>
          Click here to try to log back in.
        </Link>
        {/* </Heading> */}
      </PageBody>
    </React.Fragment>
  );
};
